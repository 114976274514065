:root {
  --color-white: #ffffff;
  --color-drk-red: #f2413b;

  --min-response-width: 430px;
}

.positionRel {
  position: relative !important;
}

.fontS12Px {
  font-size: 12px;
}
.fontS13Px {
  font-size: 13px;
}
.fontS14Px {
  font-size: 14px;
}
.fontS15Px {
  font-size: 15px;
}
.fontS16Px {
  font-size: 16px;
}
.fontS18Px {
  font-size: 18px;
}
.fontS20Px {
  font-size: 20px;
}
.fontS25Px {
  font-size: 25px;
}

.pd10px {
  padding: 10px;
}

.mg10px15px {
  margin: 10px 15px;
}
.mgL5px {
  margin-left: 5px;
}

.mgT10px {
  margin-top: 10px;
}
.mgT15px {
  margin-top: 15px;
}
.mgT25px {
  margin-top: 25px;
}

.txtAlignCenter {
  text-align: center;
}

.borderR10px {
  border-radius: 10px !important;
}
.bgWhite {
  background: var(--color-white) !important;
}

.viewCenter {
  display: flex !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flexDCol {
  flex-direction: column;
}

.dialogView {
  position: fixed;
  height: 100vh;
  width: var(--min-response-width);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}
.dialogView .dialogContents {
  position: fixed;
  bottom: 0;
  padding: 36px 24px;
  background: #ffffff;
  width: var(--min-response-width);
  overflow: scroll;
  max-height: 100vh;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.dialogView .dialogContents .headLine {
  font-size: 18px;
  font-weight: 500;
}

.dialogView .dialogContents .paraGraph {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.dialogView .dialogContents .media {
  width: 100%;
}

.dialogView .dismissBtn {
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
  color: var(--color-white);
  text-align: center;
  padding: 8px 15px;
  background: rgba(0, 0, 0, 0.8) !important;
}

.dialogActive .dialogContents {
  animation: slideUpAnimFrames 0.3s ease-out;
  animation-iteration-count: 1;
}

.dialogClose .dialogContents {
  animation: slideDownAnimFrames 0.3s ease-out;
  animation-iteration-count: 1;
}

.dialogHide {
  display: none;
  z-index: -100 !important;
}

@keyframes slideUpAnimFrames {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownAnimFrames {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView,
  .dialogView .dialogContents {
    width: 100% !important;
  }
}
