:root {
  --primary-color: #61A9FF;
  --color-blue: #0093ff;
  --color-orange: #ffa33b;
  --color-green: #00c282;
  --color-red: #fa3c09;
  --color-green: #00c282;
  --color-pink: #eb43dd;
  --color-white: #ffffff;

  --game-resp-width: 430px;
}

.positionRel {
  position: relative !important;
}

.w100 {
  width: 100%;
}

.w10px {
  width: 10px !important;
}

.w30px {
  width: 30px !important;
}

.w40px {
  width: 40px !important;
}

.w50px {
  width: 50px !important;
}

.h50px {
  height: 50px !important;
}

.hw12px {
  height: 12px;
  width: 12px;
}

.fontS12Px {
  font-size: 12px;
}

.fontS13Px {
  font-size: 13px;
}

.fontS14Px {
  font-size: 14px;
}

.fontS16Px {
  font-size: 16px;
}

.fontS18Px {
  font-size: 18px;
}

.fontS20Px {
  font-size: 20px;
}

.fontS25Px {
  font-size: 25px;
}

.fontS30Px {
  font-size: 30px;
}

.fontW400 {
  font-weight: 400;
}

.fontW700 {
  font-weight: 700;
}

.pd10px {
  padding: 10px;
}

.pd15px {
  padding: 15px;
}

.pd0px15px {
  padding: 0px 15px;
}

.pd10px15px {
  padding: 10px 15px;
}

.mg10px15px {
  margin: 10px 15px;
}

.mgL5px {
  margin-left: 5px;
}

.mgL10px {
  margin-left: 10px;
}

.mgT5px {
  margin-top: 5px;
}

.mgT10px {
  margin-top: 10px;
}

.mgT15px {
  margin-top: 15px;
}

.mgT25px {
  margin-top: 25px;
}

.mgT50px {
  margin-top: 50px;
}

.mgT60px {
  margin-top: 60px;
}

.borderR10px {
  border-radius: 10px !important;
}

.clWhite {
  color: #FFFFFF !important;
}

.clLightWhite {
  color: rgba(255, 255, 255, 0.8) !important;
}

.bgOrange {
  background: var(--color-orange) !important;
}

.bgBlue {
  background: var(--color-blue) !important;
}

.bgWhite {
  background: var(--color-white) !important;
}

.bgRed {
  background: var(--color-red) !important;
}

.bgGreen {
  background: var(--color-green) !important;
}

.bgPink {
  background: var(--color-pink);
}

.bgLightWhite {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bgPrimary {
  background: var(--primary-color) !important;
}

.bgCustom1 {
  background: #61A9FF;
}


.borderRed {
  border: 1px solid #fd565c;
}

.borderGreen {
  border: 1px solid #47ba7c;
}

.borderRadius50 {
  border-radius: 50%;
}

.bgActiveGreen {
  color: var(--color-white);
  background: var(--color-green) !important;
}



.colorRed {
  color: var(--color-red);
}

.colorGreen {
  color: var(--color-green);
}

.colorPink {
  color: var(--color-pink);
}

.colorGreenViolet {
  background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.colorRedViolet {
  background: linear-gradient(180deg, #fd565c 50.96%, #b659fe 50.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.rowView {
  display: flex;
}

.colView {
  display: flex;
  flex-direction: column;
}

.viewCenter {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.alignItemsCenter {
  align-items: center;
}

.jcSpaceBet {
  justify-content: space-between;
}

.flexDCol {
  flex-direction: column;
}

.hideView {
  display: none !important;
}


.letterSpacing1p1px {
  letter-spacing: 1.05px;
}

.GameTopBar {
  position: fixed;
  top: 0;
  z-index: 120;
  background: rgb(43, 50, 112);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;

  width: var(--game-resp-width);
}

.GameTopBar img {
  height: 35px;
}

.GameTopBar a img {
  height: 22px;
  width: 22px;
}

.WalletView {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 3.57333rem;
  background: #ffffff;
  position: relative;
  z-index: 0;
  padding: 15px;
  margin-top: 65px;
  border-radius: 15px;
  /* background-image: url(./icons/6.png), -webkit-linear-gradient(297deg, #374992 5.33%, #4675D2 93.42%); */
  background-image: url(./icons/6.png), linear-gradient(153deg, #374992 5.33%, #4675D2 93.42%);
  background-repeat: no-repeat;
  background-size: contain;
}

.WalletView p {
  color: var(--color-white);
  font-size: 23px;
  font-weight: 500;
}

.WalletView label {
  color: var(--color-white);
  font-size: 13px;
  font-weight: 100;
  letter-spacing: 1.2px;
}

.WalletView img {
  height: 18px;
}

.WalletView .btn {
  padding: 6px 20px;
  border-radius: 25px;
  color: var(--color-white);
  text-decoration: none;
}

.WalletView .btn2 {
  margin-left: 15px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
}


.GameOptionsChooseView {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 10px;
  color: #4a6aa2;
  background: #1e234d;
}

.GameOptionsChooseView .GameOptionsChooseViewItem {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
}

.GameOptionsChooseView .GameOptionsSelectedView {
  color: #ffffff;
  background: -webkit-linear-gradient(297deg, #374992 5.33%, #4675d2 93.42%);
  background: linear-gradient(153deg, #374992 5.33%, #4675d2 93.42%);
  box-shadow: 0 -0.05333rem 0.16rem #92a7ff inset;
  border-radius: 10px;
}

.countDownTimerView span {
  display: inline-block;
  text-align: center;
  padding: 0 4px;
  color: #383b45;
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
  margin: 0 2px;
  height: 32px;
  width: 26px;
  line-height: 32px;
  border-radius: 5px;
  background: #ffffff;
}

.res-app-logo {
  height: 45px !important;
}

.res-wallet-btns a {
  font-size: 18px !important;
  padding: 5px 35px !important;
  text-decoration: none;
}

.res-wallet-btns a:last-child {
  margin-left: 15px;
}


.res-ft-sz-18 {
  font-size: 18px;
}

.shortcutResultView{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shortcutResultView img {
  height: 25px;
  width: 25px;
}

.GameBettingView {
  margin: 0.48rem auto 0;
  background-image: url(./icons/5.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0.01333rem center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  color: #8f5206;
}


.GameTimeoutView {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
}

.GameTimeoutView h1 {
  height: 180px;
  width: 130px;
  padding: 0 15px;
  font-size: 9em;
  background: var(--primary-color);
}

.controlView {
  background: #2B3270 !important;
}

.joinColorView {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 15px;
}

.joinColorView div {
  padding: 10px;
  border-radius: 5px;
}

.joinNumView {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  place-items: center;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 5px;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}

.joinNumView img {
  height: 55px !important;
}

.joinBigSmallView {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
  padding: 10px 30px;
}

.joinBigSmallView div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  height: 38px !important;
}

.joinBigSmallView div:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.joinBigSmallView div:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.joinRandomView {
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-columns: repeat(5, auto);
  padding: 0px 15px;
  margin-top: 15px;
}

.joinRandomView div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.05);
}

.joinRandomView div:first-child {
  background: transparent;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.randomNumSelectedAnim {
  transform: scale(0.9);
}

/* css: tabview */
.tabOptionsView {
  width: 100%;
  display: flex;
  align-items: center;
}

.tabOptionsView .tabOptionViewItem {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  height: 48px;
  white-space: nowrap;
  line-height: 48px;
  transition: .2s;
  cursor: pointer;
  overflow: hidden;
  font-size: 18px;
  margin-top: -10px;
  color: rgba(0, 0, 0, 0.6);
  background: #FFFFFF;
}

.tabOptionsView .tabSelectedView {
  color: #FFFFFF;
  background: -webkit-linear-gradient(top, #2AAAF3 0%, #2979F2 100%);
  background: linear-gradient(180deg, #2AAAF3 0%, #2979F2 100%);
}

.loadMoreBtnsView {
  padding: 30px 10px;
}

.loadMoreBtnsView div {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.loadMoreBtnsView div.pageNumShowView {
  color: var(--color-white);
  width: 120px !important;
}

.loadMoreBtnsView div.notSelectedView {
  background: rgba(0, 0, 0, 0.1) !important;
}

.loadMoreBtnsView div.selectedView {
  background: -webkit-linear-gradient(297deg, #374992 5.33%, #4675d2 93.42%);
  background: linear-gradient(153deg, #374992 5.33%, #4675d2 93.42%);
}


.gameRecordsView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.gameRecordsView span {
  width: 30px;
  font-size: 15px;
}

.gameRecordsView span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
}

.gameRecordsView span:nth-child(4) {
  height: 25px;
  width: 25px;
}

.myGameRecordsView {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.myGameRecordsViewItem {
  display: flex;
  flex-direction: column;
}

.myGameRecordsView .myGameRecordsViewItem:not(:first-child) {
  margin-top: 15px;
}

.myGameRecordsViewItem .myGameRecordsContents {
  padding: 10px 0;
}

.myGameRecordsViewItem:not(:last-child) .myGameRecordsContents {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.08);
}

.myGameRecordsViewItem .resultShortView {
  height: 45px;
  width: 55px;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
}

.myGameRecordsViewItem .mygameShortDetailsView {
  width: 100%;
  margin-left: 15px;
}

.myGameRecordsViewItem .mygameShortDetailsView span {
  display: block;
}

.myGameRecordsViewItem .mygameShortDetailsView .gameShortDetailsResult {
  font-size: 13px;
  padding: 2px 15px;
  border-radius: 5px;
}

.myGameRecordsViewItem .gameShortDetailsResultGreen {
  color: #47ba7c;
  border: 1px solid #47ba7c;
}

.myGameRecordsViewItem .gameShortDetailsResultRed {
  color: #fd565c;
  border: 1px solid #fd565c;
}


.mygameRecordsDetails {
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.7);
}

.mygameRecordsDetailsTitle {
  font-size: 20px;
}

@media (max-width: 450px) {

  /* responsive width */
  .GameTopBar {
    width: 100% !important;
  }
}

@media (max-width: 400px) {
  .joinNumView img {
    height: 50px !important;
  }
}

@media (max-width: 370px) {
  .joinNumView img {
    height: 45px !important;
  }
}