/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&display=swap');

:root {
    --primary-color: #fa3c09;
    --white-color: #FFFFFF;

    --red-color: #fa3c09;
    --green-color: #00c282;
    --dark-green-color: #27AE60;
    --blue-color: #0093ff;
    --sky-blue-color: #aacdfa;
    --cyan-blue-color: #1aa4af;
    --violet-color: #6655d3;
    --yellow-color: #ffc107;
    --orange-color: #ffa33b;
    --black-color: #000000;
    --puff-color: #ffdec3;
    --color-violet: #eb43dd;

    --grey-color: #666;
    --light-grey-color: #959ea6;

    --simple-black: rgba(0, 0, 0, 0.5);
    --light-black: rgba(0, 0, 0, 0.05);
    --light-black-2: rgba(0, 0, 0, 0.9);

    --simple-white: rgba(255, 255, 255, 0.5);
    --light-white: rgba(255, 255, 255, 0.05);
    --min-response-width: 430px;

    --bg-app-gradient-1: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
}

body {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* font-family: 'Rubik', sans-serif; */
    font-family: 'Kanit', sans-serif;
}

/* hide scrollbar */
.hide-sb::-webkit-scrollbar {
    display: none !important;
}

.app-bg {
    background: #22275B;
    /* background: #f7f8ff; */

    /* 9195a3 grey */
}



/* dragon vs tiger css */
.bg-dragontiger {
    background-image: url(./components/icons/DRAGONVSTIGER/bg.png);
    background-size: contain;
    background-color: #4f54af;
}



/* circle css */
.bg-circle {
    background-image: url(./components/Games/Wheelocity/icons/bg.jpg);
    background-size: contain;
    background-color: #4f54af;
}

.circle-trans {
    transition: transform 10s cubic-bezier(0.2, 0.93, 0.43, 1) 0s;
}

.circle-trans-none {
    transition: none 0s ease 0s;
}



/* background game time & periods */
.bg-gtp {
    /* position: relative;
    background-image: url(./components/icons/game-timming-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 0px !important;
    margin-bottom: 0px !important; */

    /* height: 2.66667rem; */
    margin: 0.48rem auto 0;
    background-image: url(./components/Games/Wingo/icons/5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0.01333rem center;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    color: #8f5206;
}

.bg-category-1 {
    position: relative;
    background-image: url(./components/icons/othericons/10.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}



/* aviator css */
.airplane-v {
    height: 30px;
    width: 80px;
    z-index: 10;
    animation: airplane-move-anim .2s infinite 0s;
}


@keyframes airplane-move-anim {
    0% {
        background: url(https://static.lotterybox.com/game/fly2/7.png);
        background-size: cover;
    }

    100% {
        background: url(https://static.lotterybox.com/game/fly2/8.png);
        background-size: cover;
    }
}


.speedMovespot {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: var(--white-color);
}

.tns-t-50 .speedMovespot {
    top: 40%;
    transform: translateY(-50%);
}

.tns-l-50 .speedMovespot {
    left: 40%;
    transform: translateX(-50%);
}

.driftAnimRtL {
    animation: driftRtLAnim 18s 2s linear infinite both;
}

.driftAnimTtB {
    animation: driftTtBAnim 18s 2s linear infinite both;
}


.bl-area {
    display: flex;
    align-items: center;
    margin: .25rem 0 !important;
}

.bal-refresh-btn {
    display: flex;
    align-items: center;
}

.bal-refresh-btn img {
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s;
    margin-left: 6px;
    vertical-align: baseline;
}

#show-balance-tv {
    font-weight: 700;
    font-size: 24px;
}






.reward-options-view {
    padding: 10px 20px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
}

.reward-options-view div {
    display: flex;
    align-items: center;
}

.reward-options-view span {
    font-size: 12px;
    font-weight: 700;
    color: #383b45;
    margin-left: 12px;
}

.reward-options-view img {
    height: 35px;
}

.available-games-view {
    display: grid;
    grid-gap: 10px;
    padding: 10px 15px;
    grid-template-columns: repeat(2, 1fr);
}

/* floating view */
.flt-view .flt-view-details {
    width: 95%;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 12px;

    border-radius: 4px;
    transition: .2s;
    margin-bottom: 6px;
    color: #333;
    background: #f9fcff;
    border: 1px solid #d0ebff;
    transform: translateX(-50%);
}




/* view align column wise */
.col-view {
    display: flex;
    flex-direction: column;
}

/* view align row wise */
.row-view {
    display: flex;
    align-items: center;
}

/* view space between */
.sb-view {
    justify-content: space-between;
}

.padding_15 {
    padding: 15px;
}

.mrg_top_50 {
    margin-top: 50px;
}

.m-top-c {
    margin-top: 1.5rem !important
}

.m-bottom-n {
    margin-bottom: 0.5rem !important
}

.m-bottom-c {
    margin-bottom: 1.5rem !important
}

/* .txt_small{
    font-size: 12px;
}
.txt_medium{
    font-size: 18px; 
} */
.f-size-max {
    font-size: 24px;
}

.t-color-blk {
    color: #383b45;
}

.f-weight-n {
    font-weight: 500;
}

.my-account-view .app-btn {
    background-color: #fff;
    height: 40px;
    line-height: 40px;
    color: #0093ff;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #0093ff;
    transition: 0.3s;
    text-align: center;
    white-space: nowrap;
}

/* .account-details-bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
} */

.more_options_view {
    padding: 10px;
    margin-top: 80px;
}

.more_option {
    position: relative;
    width: 100%;
}

.more_option img {
    height: 40px;
}

.more_option .arrow-icon {
    position: absolute;
    right: 0;
    height: 20px;
}

/* responsive width */
.res-wth {
    width: var(--min-response-width) !important;
}

.ovf-hidden {
    overflow: hidden !important;
}

.ovf-scrl-x {
    overflow-x: scroll !important;
}

.ovf-scrl-y {
    overflow-y: scroll !important;
}



/* position fixed */

/* full screen position fixed */
.ps-fx {
    position: fixed;
}

.ps-tp--15px {
    top: -15px;
}

.ps-tp--20px {
    top: -20px;
}

.ps-tp--27px {
    top: -27px;
}

.ps-tp {
    top: 0;
}

.ps-tp-30px {
    top: 30px;
}

.ps-tp-180px {
    top: 180px;
}

.ps-tp-50 {
    top: 50%;
}

.ps-tp--10px {
    top: -10px;
}

.ps-tp--170 {
    top: -170px !important;
}

.ps-btm {
    bottom: 0;
}

.ps-btm-10 {
    bottom: 10px;
}

.ps-btm-20 {
    bottom: 20px;
}

.ps-btm-40 {
    bottom: 40px;
}

.ps-btm--600 {
    bottom: -600px !important;
}

.ps-btm--50 {
    bottom: -50px !important;
}

.ps-lf {
    left: 0;
}

.ps-lf-10 {
    left: 10px;
}

.ps-lf--5 {
    left: -5px;
}

.ps-lf--10 {
    left: -10px;
}

.ps-lf-40 {
    left: 40px;
}

.ps-lf--600 {
    left: -600px !important;
}

.ps-lf-50 {
    left: 50%;
}

.ps-rgt {
    right: 0;
}

.ps-rgt-10 {
    right: 10px;
}

.pr-v {
    position: relative !important;
}

.ps-rl {
    position: relative !important;
}

.ps-ab {
    position: absolute !important;
}

.t-form-x {
    transform: translateX(-50%);
}

.t-form-y {
    transform: translateY(-50%);
}

.t-form-xy {
    transform: translate(-50%, -50%);
}

.t-form-y-100 {
    transform: translateY(-100%);
}


.lodr-v {
    border: 2px solid var(--light-white);
    border-radius: 50%;
    border-top: 2px solid var(--white-color);
    width: 40px;
    height: 40px;
    -webkit-animation: spin 0.5s linear infinite;
    /* Safari */
    animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



/* betting dialog view */

.dialogContents {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 5px;
    z-index: 1000;
    background: var(--white-color);
}

.dialogContents #dialogTitle {
    height: 46px;
    line-height: 46px;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
}

@keyframes slideFromBottomAnim {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.disable-view {
    filter: grayscale(100);
}

.disable-view .dsbl-bg {
    background: #ccc !important;
    pointer-events: none !important;
    box-shadow: none !important;
    border: none !important;
    color: var(--white-color);
    filter: grayscale(100);
}


.s-bet-btn {
    height: 40px !important;
    line-height: 40px !important;
    width: auto !important;
    padding: 0 !important;
    border-radius: 2px !important;
    background-color: #ebf7ff !important;
    /* border: 1px solid #cdd4ff !important; */
}





/* extra common used classes */
.smpl-btn {
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    white-space: nowrap;
    text-align: center;
    transition: transform .1s;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 5px;
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, 0.1);
}

.smpl-btn-l {
    height: 28px !important;
    width: 28px !important;
}

/* input slider codes */
.slider-range {
    -webkit-appearance: none;
    border-radius: 12px;
    width: 100%;
    height: 20px;
    background: #b6e0ff;
    outline: none;
    -webkit-transition: .2s;
}

.slidPro {
    width: 48%;
    height: 20px;
    background: #0093ff;
    border-radius: 12px;
    position: absolute;
    top: 0;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0093ff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}


/* bottom navbar */
.btm-navbar {
    height: 65px;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    box-shadow: 0.1px 4px 8px 2px rgba(0, 0, 0, 0.1);
}


/* betting dialog theme */
.a-smpl-btn {
    background: #f2413b;
}

/* theme text colour */
.dlg-thm-blue .dlg-thm-txt {
    color: var(--white-color) !important;
    background: var(--blue-color) !important;
}

.dlg-thm-green .dlg-thm-txt {
    color: var(--white-color) !important;
    background: var(--green-color) !important;
}

.dlg-thm-red .dlg-thm-txt {
    color: var(--white-color) !important;
    background: var(--red-color) !important;
}

.dlg-thm-violet .dlg-thm-txt {
    color: var(--white-color) !important;
    background: var(--violet-color) !important;
}

.dlg-thm-orange .dlg-thm-txt {
    color: var(--white-color) !important;
    background: var(--orange-color) !important;
}

.dlg-thm-blue .active-btn {
    background: var(--blue-color) !important;
}

.dlg-thm-green .active-btn {
    background: var(--green-color) !important;
}

.dlg-thm-red .active-btn {
    background: var(--red-color) !important;
}

.dlg-thm-violet .active-btn {
    background: var(--violet-color) !important;
}

.dlg-thm-orange .active-btn {
    background: var(--orange-color) !important;
}


/* 👉 toast message codes 😎 */
.notice-dialog{
    position: fixed;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
}
.tst-content {
    max-width: 250px;
    min-width: 220px;
    border-radius: 6px;
    transition: 0.2s;
    box-shadow: 0 0 10px #898989;
    background: var(--white-color);
}


.active-bx-view {
    border-radius: 8px;
    color: var(--black-color);
    background: var(--sky-blue-color);
}


.w-fit-content {
    width: fit-content;
}

.max-w-100 {
    max-width: 100% !important;
}

.w-1p5-rem {
    width: 1.6rem !important;
}

.w-1p2-rem {
    width: 1.25rem !important;
}

.w-1p87-rem {
    width: 1.87rem !important;
}

.w-2p5-rem {
    width: 2.5rem !important;
}

.w-100 {
    width: 100% !important;
}

.w-90 {
    width: 90% !important;
}

.w-85 {
    width: 85% !important;
}

.w-70 {
    width: 70% !important;
}

.w-50 {
    width: 50% !important;
}

.w-45 {
    width: 45% !important;
}

.w-30 {
    width: 30% !important;
}

.w-15 {
    width: 15% !important;
}

.w-10-p {
    width: 10px !important;
}

.w-15-p {
    width: 15px !important;
}

.w-20-p {
    width: 20px !important;
}

.w-30-p {
    width: 30px !important;
}

.w-40-p {
    width: 40px !important;
}

.w-50-p {
    width: 50px !important;
}

.w-60-p {
    width: 60px !important;
}

.w-65-p {
    width: 65px !important;
}

.w-80-p {
    width: 80px !important;
}

.w-100-p {
    width: 100px !important;
}

.w-125-p {
    width: 150px !important;
}

.w-140-p {
    width: 140px !important;
}

.w-300-p {
    width: 300px !important;
}

.w-350-p {
    width: 350px !important;
}


/* height & width */
.max-h-85-p {
    max-height: 85px !important;
}

.h-auto {
    height: auto !important;
}

.h-100 {
    height: 100% !important;
}

.h-100vh {
    height: 100vh !important;
}

.h-5-p {
    height: 5px !important;
}

.h-1p5-rem {
    height: 1.6rem !important;
}

.h-1p2-rem {
    height: 1.25rem !important;
}

.h-1p87-rem {
    height: 1.87rem !important;
}

.h-2p5-rem {
    height: 2.5rem !important;
}

.h-10-p {
    height: 10px !important;
}

.h-15-p {
    height: 15px !important;
}

.h-18-p {
    height: 18px !important;
}

.h-20-p {
    height: 20px !important;
}

.h-25-p {
    height: 25px !important;
}

.h-27-p {
    height: 27px !important;
}

.h-30-p {
    height: 30px !important;
}

.h-40-p {
    height: 40px !important;
}

.h-45-p {
    height: 45px !important;
}

.h-50-p {
    height: 50px !important;
}

.h-52-p {
    height: 52px !important;
}

.h-55-p {
    height: 55px !important;
}

.h-60-p {
    height: 60px !important;
}

.h-65-p {
    height: 65px !important;
}

.h-70-p {
    height: 70px !important;
}

.h-73-p {
    height: 73px !important;
}

.h-85-p {
    height: 85px !important;
}

.h-120-p {
    height: 120px !important;
}

.h-140-p {
    height: 140px !important;
}

.h-150-p {
    height: 150px !important;
}

.h-200-p {
    height: 200px !important;
}

.h-230-p {
    height: 230px !important;
}

.h-250-p {
    height: 250px !important;
}

.h-300-p {
    height: 300px !important;
}

.h-340-p {
    height: 340px !important;
}

.h-350-p {
    height: 350px !important;
}

.h-380-p {
    height: 380px !important;
}

.h-450-p {
    height: 450px !important;
}

.h-470-p {
    height: 470px !important;
}

.h-485-p {
    height: 485px !important;
}

.h-550-p {
    height: 550px !important;
}

.res-h-100 {
    height: 100px !important;
}

.res-h-60-p {
    height: 60px !important;
}

/* height & width */
.h-w-100 {
    height: 100px !important;
    width: 100px !important;
}

.h-w-80 {
    height: 80px !important;
    width: 80px !important;
}

.h-w-70 {
    height: 70px !important;
    width: 70px !important;
}

.h-w-65 {
    height: 65px !important;
    width: 65px !important;
}

.h-w-60 {
    height: 60px !important;
    width: 60px !important;
}

.h-w-55 {
    height: 55px !important;
    width: 55px !important;
}

.h-w-45 {
    height: 45px !important;
    width: 45px !important;
}

.h-w-40 {
    height: 40px !important;
    width: 40px !important;
}

.h-w-36 {
    height: 36px !important;
    width: 36px !important;
}

.h-w-32 {
    height: 32px !important;
    width: 32px !important;
}

.h-w-28 {
    height: 28px !important;
    width: 28px !important;
}

.h-w-26 {
    height: 26px !important;
    width: 26px !important;
}

.h-w-25 {
    height: 25px !important;
    width: 25px !important;
}

.h-w-20 {
    height: 20px !important;
    width: 20px !important;
}

.h-w-22 {
    height: 22px !important;
    width: 22px !important;
}

.h-w-15 {
    height: 15px !important;
    width: 15px !important;
}

.h-w-12 {
    height: 12px !important;
    width: 12px !important;
}


.h-128-w-96 {
    height: 128px !important;
    width: 96px !important;
}

.h-115-w-164 {
    width: 115px !important;
    height: 164px !important;
}

.h-200-w-150 {
    height: 200px !important;
    width: 150px !important;
}

.h-1200-w-1200 {
    width: 1200px !important;
    height: 1200px !important;
}

.ovf-scrl {
    overflow: scroll !important;
}

.g-v-2 {
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.g-v-3 {
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.g-v-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.g-v-5 {
    display: grid;
    row-gap: 10px;
    column-gap: 5px;
    grid-template-columns: repeat(5, 1fr);
}

.g-v-6 {
    display: grid;
    row-gap: 10px;
    column-gap: 5px;
    grid-template-columns: repeat(6, 1fr);
}

.g-v-7 {
    display: grid;
    row-gap: 10px;
    column-gap: 5px;
    grid-template-columns: repeat(7, 1fr);
}

.g-v-10 {
    display: grid;
    row-gap: 10px;
    column-gap: 5px;
    grid-template-columns: repeat(10, 1fr);
}

.g-c-gap-8 {
    column-gap: 8px;
}

.g-r-gap-8 {
    row-gap: 8px;
}

.g-r-gap-30 {
    row-gap: 30px !important;
}


/* .t-countview span{
    display: inline-block;
    text-align: center;
    padding: 0 4px;
    color: #383b45;
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
    margin: 0 2px;
    height: 32px;
    width: 26px;
    line-height: 32px;
    border-radius: 5px;
} */

.tab-slct-v {
    display: flex;
    align-items: center;
}

.tab-slct-v .tab-in-v {
    border-radius: 6px 6px 0 0;
    box-shadow: 0px -3px 6px 0px #6e6e6e30;
    height: 48px;
    white-space: nowrap;
    line-height: 48px;
    transition: .2s;
    cursor: pointer;
    overflow: hidden;
    color: #979797;
    font-size: 18px;
    margin-top: -10px;
    background: #ffffff;
}

.tab-slct-v .active {
    box-shadow: 0px -3px 4px 0px #6e6e6e30;
    border-bottom: 3px solid #1e88e5;
    color: #383b45;
}

.tab-h-active {
    color: var(--white-color);
    border-bottom: 3px solid var(--white-color);
}


/* table view */
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

.tbl-view div {
    width: 100%;
    text-align: center;
}

.tbl-view .bg-lb {
    background: #f1f3ff;
}

.tbl-view div p {
    padding: 5px 10px;
}

.tbl-view .br {
    border: 1px solid #cdd4ff;
}

.tbl-view .br-l {
    border-left: 1px solid #cdd4ff;
}

.tbl-view .br-r {
    border-right: 1px solid #cdd4ff;
}

.tbl-view .br-b {
    border-bottom: 1px solid #cdd4ff;
}

.tbl-view .br-t {
    border-top: 1px solid #cdd4ff;
}

.tbl-view .br-a-b {
    border-top: 1px solid #cdd4ff;
    border-left: 1px solid #cdd4ff;
    border-right: 1px solid #cdd4ff;
}


.line-v {
    height: 45px !important;
    width: 2px !important;
    background: var(--white-color);
}

.line-hv-grey {
    height: 1px !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.05);
}


/* custom input */
.cutm-inp-bx {
    display: flex;
    width: 100%;
    padding: 0;
    height: 56px;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
}

.cutm-inp {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    transition: .1s;
    height: fit-content;
    padding: 10px;
    color: #000000;
}

.inp-ph-color-l-white::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.inp-ph-l-color::placeholder {
    color: #cccccc !important;
}


/* Hide Arrows From Input Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


/* text codes */
.txt-deco-n {
    text-decoration: none !important;
}

.txt-deco-u {
    text-decoration: underline !important;
}

.txt-a-end {
    text-align: end !important;
}

.txt-a-center {
    text-align: center !important;
}


/* colour codes ----------- */
.cl-primary {
    color: var(--primary-color) !important;
}

.cl-golden {
    color: #d9ac4f !important;
}

.cl-green {
    color: var(--green-color);
}

.cl-red {
    color: var(--red-color);
}

.cl-drk-red {
    color: #f2413b !important;
}

.cl-violet {
    color: var(--violet-color);
}

.cl-grey {
    color: var(--grey-color) !important;
}

.cl-l-grey {
    color: var(--light-grey-color) !important;
}

.cl-black {
    color: #383b45 !important;
}

.cl-l-black {
    color: rgba(0, 0, 0, 0.6) !important;
}

.cl-l-black-2 {
    color: var(--light-black-2) !important;
}

.cl-drk-black {
    color: var(--black-color) !important;
}

.cl-white {
    color: var(--white-color) !important;
}

.cl-l-white {
    color: rgba(255, 255, 255, 0.8);
}

.cl-l-white-2 {
    color: rgba(255, 255, 255, 0.6);
}

.cl-l-white-3 {
    color: rgba(255, 255, 255, 0.9);
}

.cl-blue {
    color: var(--blue-color);
}

.cl-cyan-blue {
    color: var(--cyan-blue-color) !important;
}

.cl-custom-blue {
    color: #1b8ed1 !important;
}

.cl-drk-grey {
    color: #8f5206;
}

.cl-gv {
    /* background: linear-gradient(to right, var(--green-color), var(--violet-color)) !important;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; */

    background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.cl-rv {
    /* background: linear-gradient(to right, var(--red-color), var(--violet-color)) !important;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; */

    background: linear-gradient(180deg, #fd565c 50.96%, #b659fe 50.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/* background colours */
.bg-primary {
    background: var(--primary-color) !important;
}

.bg-black {
    background: var(--black-color) !important;
}

.bg-green {
    background: var(--green-color) !important;
}

.bg-drk-green {
    background: var(--dark-green-color) !important;
}

.bg-l-green {
    background: #00c27a1a !important;
}

.bg-red {
    background: var(--red-color) !important;
}

.bg-red-2 {
    background: #ff595a !important;
}

.bg-violet {
    background: var(--violet-color) !important;
}

.bg-violet-2 {
    background: var(--color-violet) !important;
}

.bg-blue {
    background: var(--blue-color);
}

.bg-l-blue {
    background: #ebf7ff !important;
}

.bg-drk-blue {
    background: #0760a1 !important;
}

.bg-yellow {
    background: var(--yellow-color) !important;
}

.bg-drk-yellow {
    background: rgb(255, 218, 126);
}

.bg-rv {
    background: linear-gradient(-60deg, var(--red-color) 50%, var(--violet-color) 50%) !important;
    box-shadow: rgb(102 85 211 / 40%) 0px 0px 8px
}

.bg-gv {
    background: linear-gradient(-60deg, var(--green-color) 50%, var(--violet-color) 50%) !important;
    box-shadow: rgb(102 85 211 / 40%) 0px 0px 8px
}

.bg-l-black {
    background: var(--simple-black);
}

.bg-white {
    background: #FFFFFF;
}

.bg-l-white {
    background: var(--light-white) !important;
}

.bg-l-white-2 {
    background: rgba(255, 255, 255, 0.3) !important;
}

.bg-l-white-3 {
    background: rgba(255, 255, 255, 0.1) !important;
}

.bg-l-white-4 {
    background: rgba(255, 255, 255, 0.4) !important;
}

.bg-s-white {
    background: var(--simple-white);
}

.bg-orange {
    background: var(--orange-color);
}

.bg-drk-orange {
    background: #fc940e;
}

.bg-cyan-blue {
    background: #1aa4af !important;
}

.bg-extm-l-white {
    background: #f9fcff !important;
}

.bg-grey-2 {
    background: #a5a5a5 !important;
}

.bg-grey-3 {
    background: #f6f6f6 !important;
}

.bg-grey {
    background: #ededed;
}

.bg-l-grey {
    background: #f5f5f5 !important;
}

.bg-extm-l-grey {
    background: #f7f6fb !important;
}

.bg-puff {
    background: var(--puff-color);
}

.bg-grad-ry {
    background: linear-gradient(90deg, rgba(180, 90, 58, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}

.bg-grad-lgt-drk-blue {
    background: linear-gradient(45deg, #0093ff, #0760a1);
}

.bg-grad-grey {
    background: linear-gradient(90deg, #6F7381 0%, #A9AAB5 100%) !important;
}

.bg-custom-lightblue {
    background: #2B3270 !important;
}

/* .bg-custom-blue-2{
    background: #2aaaf3;
} */

.bg-custom-blue {
    color: #FFFFFF;
    background: #2196f3 !important;
}

.bg-gold-grad {
    background: linear-gradient(153.24deg, #c4933f 5.33%, #fae59f 93.42%);
}

.bg-gold-grad-2 {
    background: linear-gradient(180deg, #F6E3A3 0%, #D2A753 100%);
}

.bg-gold-grad-3 {
    background: linear-gradient(-45deg, transparent 0.13333rem, #FAE59F 0) 0 0;
}

.bg-gold-grad-4 {
    background: linear-gradient(180deg, #EAB456 0%, #FFECAC 100%);
    box-shadow: 0 0.02667rem 0.08rem #fae59f inset, 0 -0.02667rem 0.08rem #fae59f inset;
}

.bg-red-grad {
    background: linear-gradient(90deg, rgb(206, 2, 4) 0%, rgb(242, 64, 58) 100%);
}

.bg-red-grad-2 {
    background: linear-gradient(180deg, #f13d38 0%, #ff7070 100%);
}

.bg-red-grad-3 {
    background: linear-gradient(90deg, #dd2224 0%, #ff504a 100%);
}

.bg-red-grad-4 {
    background: linear-gradient(90deg, rgb(206, 2, 4) 0%, rgb(242, 64, 58) 100%);
}

.bg-red-grad-5 {
    background: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
}

.bg-red-grad-6 {
    background: linear-gradient(180deg, #ff867a 0%, #f95959 100%);
}

.bg-red-grad-7 {
    background: linear-gradient(125.43deg, #ff8e89 12.38%, #ffc3a2 87.13%);
}

.bg-red-grad-8 {
    background: linear-gradient(180deg, #fea175 0%, #fe5b5b 100%);
}

.bg-red-grad-9 {
    background: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
}

.bg-red-grad-10 {
    letter-spacing: .01333rem;
    text-shadow: 0 .05333rem .02667rem rgba(231, 65, 65, .5);
    background: linear-gradient(180deg, #ff867a 0%, #f95959 100%);
    box-shadow: 0 .05333rem #e74141;
}

.bg-grad-1 {
    background: var(--bg-app-gradient-1);
}

.bg-red-grad-active {
    background: linear-gradient(180deg, #ff8080 0%, rgba(255, 128, 128, 0) 100%);
    box-shadow: inset 0 -0.02667rem 0.08rem #ffdfdb, inset 0 0.02667rem 0.08rem #ea6b66;
}


.bg-grey-black {
    background: rgb(63, 63, 63) !important;
}

.bg-drk-grey {
    background: #8f5206;
}

.bgCustomColor4 {
    background: rgb(43, 50, 112);
}


.bg-disable-view {
    background: linear-gradient(180deg, #cfd1df 0%, #c8cada 100%);
    box-shadow: 0 0.05333rem #b6bad0;
}

.bg-transparent {
    background-color: transparent !important;
}

/* box shadows */

.bx-shadow {
    box-shadow: 0px -3px 4px 0px #6e6e6e30;
}

.bx-shadow-2 {
    box-shadow: 2px 5px 4px 2px rgba(0, 0, 0, 0.04);
}

.bx-shadow-3 {
    box-shadow: 0 .05333rem .21333rem #d0d0ed5c;
}

.bx-shdw-blk {
    box-shadow: #818181 0px 0px 6px 0px !important;
}

/* .bx-shdw-blue{
    box-shadow: 0 0 6px #0093ff;
} */

.bx-shdw-violet {
    box-shadow: 0 4px #a67bff;
}

.bx-shdw-green {
    box-shadow: 0 4px #2ba50b;
}

.bx-shdw-inset-green {
    box-shadow: rgb(80, 148, 56) -3.99091px -3.99091px 3.99091px 0px inset;
}

.bx-shdw-red {
    box-shadow: 0 4px #CB4335;
}

.bx-shdw-yellow {
    box-shadow: 0 4px #e3a036;
}

.bx-shdw-blue {
    box-shadow: 0 4px var(--sky-blue-color);
}

.bx-cyan-blue {
    box-shadow: 0 4px #058c94;
}

.bx-drk-orange {
    box-shadow: 0 4px #e68104;
}

.topbar-shadow {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* min height & width */
.min-h {
    min-height: 450px !important;
}

.min-h-100 {
    min-height: 100px !important;
}

.min-h-100vh {
    min-height: 100vh !important;
}

.min-w-90 {
    min-width: 90px !important;
}


/* font size */

.ft-sz-1p25rem {
    font-size: 1.25rem !important;
}

.ft-sz-2em {
    font-size: 2em !important;
}

.ft-sz-10em {
    font-size: 10em !important;
}

.ft-sz-10 {
    font-size: 10px !important;
}

.ft-sz-12 {
    font-size: 12px !important;
}

.ft-sz-13 {
    font-size: 13px !important;
}

.ft-sz-14 {
    font-size: 14px !important;
}

.ft-sz-15 {
    font-size: 15px !important;
}

.ft-sz-16 {
    font-size: 16px !important;
}

.ft-sz-17 {
    font-size: 17px !important;
}

.ft-sz-18 {
    font-size: 18px !important;
}

.ft-sz-20 {
    font-size: 20px !important;
}

.ft-sz-22 {
    font-size: 22px !important;
}

.ft-sz-23 {
    font-size: 23px !important;
}

.ft-sz-25 {
    font-size: 25px !important;
}

.ft-sz-28 {
    font-size: 28px !important;
}

.ft-sz-32 {
    font-size: 32px !important;
}

.ft-sz-35 {
    font-size: 35px !important;
}

.ft-sz-38 {
    font-size: 38px !important;
}

/* font weight */
.ft-wgt-b {
    font-weight: bold !important;
}

.ft-wgt-200 {
    font-weight: 200 !important;
}

.ft-wgt-400 {
    font-weight: 500 !important;
}

.ft-wgt-500 {
    font-weight: 500 !important;
}

.ft-wgt-700 {
    font-weight: 700 !important;
}

.ft-wgt-600 {
    font-weight: 600 !important;
}


/* letter spacing */
.ltr-s {
    letter-spacing: 1.2px !important;
}

.ltr-s-1p8 {
    letter-spacing: 1.8px !important;
}

.ltr-s-2p2 {
    letter-spacing: 2.2px !important;
}

/* border codes */
.br-a-primary {
    border: 1px solid #ff595a;
}

.br-a-l-grey {
    border: 1px solid var(--light-grey-color);
}

.br-btm-grey {
    border-bottom: 1px solid var(--light-grey-color);
}

.br-a-grey {
    border: 1px solid var(--grey-color);
}

.br-a-blue {
    border: 1px solid var(--blue-color);
}

.br-a-l-white {
    border: 1px solid #eceaf2 !important;
}

/* border all side light blue */
.br-a-l-blue {
    border: 1px solid #d0ebff;
}

.br-a-l-black {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.br-a-red {
    border: 1px solid #f2413b;
}

.br-a-golden {
    border: 1px solid #d9ac4f;
}

.br-b-grey {
    border-bottom: 2px solid #cccccc;
}

.br-t-grey {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.br-r-grey {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.br-btm-theme-color {
    border-bottom: 2px solid var(--primary-color);
}

.br-a-theme-color {
    border: 1px solid var(--primary-color);
}



.outline-none {
    outline: none !important;
}


/* rounded corners codes ----------- */
.br-none {
    border: none !important;
}

.br-5 {
    border-radius: 5px !important;
}

.br-7 {
    border-radius: 7px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-15 {
    border-radius: 15px !important;
}

.br-20 {
    border-radius: 20px !important;
}

.br-50 {
    border-radius: 50% !important;
}

.br-lr-b-1p5rem {
    border-radius: 0 0 1.5rem 1.5rem;
}

.br-lr-b-2p5rem {
    border-radius: 0 0 2.5rem 2.5rem;
}

/* border right top */
.br-right-t {
    border-top-right-radius: 10px !important;
}

.br-right-b {
    border-bottom-right-radius: 10px !important;
}

.br-right-t-b-30 {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

/* border left top */
.br-left-t {
    border-top-left-radius: 10px !important;
}

.br-left-b {
    border-bottom-left-radius: 10px !important;
}

.br-left-t-b-30 {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.br-t-rl-5 {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.br-t-rl-20 {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}


/* padding margins codes -----------*/

/* padding 5px */
.pd-3 {
    padding: 3px !important;
}

.pd-5 {
    padding: 5px !important;
}

.pd-8 {
    padding: 8px !important;
}

.pd-10 {
    padding: 10px !important;
}

.pd-15 {
    padding: 15px !important;
}

.pd-18 {
    padding: 18px !important;
}

.pd-20 {
    padding: 20px !important;
}

.pd-30 {
    padding: 30px !important;
}

/* padding left right top bottom */
.pd-0-10 {
    padding: 0 8px !important;
}

.pd-5-10 {
    padding: 5px 10px !important;
}

.pd-2-8 {
    padding: 2px 8px !important;
}

.pd-5-15 {
    padding: 5px 15px !important;
}

.pd-8-18 {
    padding: 8px 18px !important;
}

.pd-10-15 {
    padding: 10px 15px !important;
}

.pd-10-20 {
    padding: 10px 20px !important;
}

.pd-15-20 {
    padding: 15px 20px !important;
}

.pd-10-0 {
    padding: 10px 0px !important;
}

.pd-15-0 {
    padding: 15px 0px !important;
}

.pd-0-15 {
    padding: 0px 15px !important;
}

.pd-15-10 {
    padding: 15px 10px !important;
}

.pd-20-10 {
    padding: 20px 10px !important;
}

.pd-30-5 {
    padding: 30px 5px !important;
}

.pd-15-5 {
    padding: 15px 5px !important;
}

.pd-5-25 {
    padding: 5px 25px !important;
}

.pd-5-35 {
    padding: 5px 35px !important;
}

.pd-0-30 {
    padding: 0 30px !important;
}

.pd-t-30 {
    padding-top: 30px !important;
}

.pd-l-20 {
    padding-left: 20px !important;
}

/* margin & margin top */
.mg-10 {
    margin: 10px;
}

.mg-15 {
    margin: 15px;
}

.mg-10-15 {
    margin: 10px 15px;
}

.mg-t-5 {
    margin-top: 5px !important;
}

.mg-t-10 {
    margin-top: 10px !important;
}

.mg-t-15 {
    margin-top: 15px !important;
}

.mg-t-20 {
    margin-top: 20px !important;
}

.mg-t-25 {
    margin-top: 25px !important;
}

.mg-t-30 {
    margin-top: 30px !important;
}

.mg-t-45 {
    margin-top: 45px !important;
}

.mg-t-55 {
    margin-top: 55px !important;
}

.mg-t-70 {
    margin-top: 70px !important;
}

.mg-t-80 {
    margin-top: 80px !important;
}

.mg-t-120 {
    margin-top: 120px !important;
}

.mg-t-4em {
    margin-top: 4em !important;
}

.mg-t-5em {
    margin-top: 5em !important;
}

.mg-t-6em {
    margin-top: 6em !important;
}

.mg-t-7em {
    margin-top: 7em !important;
}

.mg-t-8em {
    margin-top: 8em !important;
}

.mg-t-11em {
    margin-top: 11em !important;
}

.mg-t-13em {
    margin-top: 13em !important;
}

.mg-t-15em {
    margin-top: 15em !important;
}

.mg-t--10 {
    margin-top: -10px !important;
}

.mg-t--20 {
    margin-top: -20px !important;
}

.mg-t--40 {
    margin-top: -40px !important;
}

.mg-t--45 {
    margin-top: -45px !important;
}

.mg-t--80 {
    margin-top: -80px !important;
}

.mg-t--90 {
    margin-top: -90px !important;
}

.mg-t--120 {
    margin-top: -120px !important;
}

.mg-t--130 {
    margin-top: -130px !important;
}

/* margin bottom*/
.mg-b-5 {
    margin-bottom: 5px !important;
}

.mg-b-10 {
    margin-bottom: 10px !important;
}

.mg-b-15 {
    margin-bottom: 15px !important;
}

.mg-b-20 {
    margin-bottom: 20px !important;
}

.mg-b-25 {
    margin-bottom: 25px !important;
}

.mg-b-50 {
    margin-bottom: 50px !important;
}

.mg-b-70 {
    margin-bottom: 70px !important;
}

.mg-b-100 {
    margin-bottom: 100px !important;
}

.mg-b-8em {
    margin-bottom: 8em !important;
}

.mg-b-10em {
    margin-bottom: 10em !important;
}

/* margin left */

.mg-l-3 {
    margin-left: 3px !important;
}

.mg-l-5 {
    margin-left: 5px !important;
}

.mg-l-10 {
    margin-left: 10px !important;
}

.mg-l-15 {
    margin-left: 15px !important;
}

.mg-l-20 {
    margin-left: 20px !important;
}

.mg-l-25 {
    margin-left: 25px !important;
}

.mg-l-30 {
    margin-left: 30px !important;
}

.mg-l-40 {
    margin-left: 40px !important;
}

.mg-l-50 {
    margin-left: 50px !important;
}

.mg-l-80 {
    margin-left: 80px !important;
}


.mg-r-5 {
    margin-right: 5px !important;
}

.mg-r-10 {
    margin-right: 10px !important;
}

/* alignment codes ---------- */

/* view center */
.v-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.a-center {
    align-items: center;
}

.a-flex-start {
    align-items: flex-start !important;
}

.a-right {
    align-items: end;
}

.jus-content-flx-center {
    justify-content: center !important;
}

.jus-content-flx-start {
    justify-content: flex-start !important;
}

.jus-content-flx-end {
    justify-content: flex-end !important;
}


/* layer & z-index */
.z-i-1 {
    z-index: 1 !important;
}

.z-i-120 {
    z-index: 120 !important;
}

.z-i-110 {
    z-index: 110 !important;
}

.z-i-100 {
    z-index: 100 !important;
}

.z-i-99 {
    z-index: 99 !important;
}

.z-i--100 {
    z-index: -100 !important;
}

.z-i-1000 {
    z-index: 1000 !important;
}


.z-i-90 {
    z-index: 90 !important;
}

.z-i-91 {
    z-index: 91 !important;
}

.z-i-92 {
    z-index: 92 !important;
}

.z-i-93 {
    z-index: 93 !important;
}

.z-i-94 {
    z-index: 94 !important;
}

.z-i-95 {
    z-index: 95 !important;
}

.z-i-96 {
    z-index: 96 !important;
}

.z-i-97 {
    z-index: 97 !important;
}

.z-i-98 {
    z-index: 98 !important;
}


/* active dialog codes */
.activeDialog {
    z-index: 1000 !important;
}

.activeDialog .dlg-c {
    animation: .3s ease-out 0s 1 slideFromBottomAnim;
}

/* opacity */
.opac-0 {
    opacity: 0 !important;
}

.opac-05 {
    opacity: 0.5 !important;
}

.opac-f {
    opacity: 1 !important;
}

/* opacity full */
.greys-100 {
    filter: grayscale(100) !important;
}


/* hide view */
.hide-v {
    display: none !important;
}

.trn-05 {
    transition: 0.5s;
}

.trn-d-0p5 {
    transition-delay: 0.5s;
}

.trn-d-1 {
    transition-delay: 1s;
}

.trn-d-1p5 {
    transition-delay: 1.5s;
}

.trn-d-2 {
    transition-delay: 2s;
}

.trn-d-2p5 {
    transition-delay: 2.5s;
}

.trn-d-3 {
    transition-delay: 3s;
}

.trn-d-3p5 {
    transition-delay: 3.5s;
}

.trn-d-4 {
    transition-delay: 4s;
}

.trn-d-4p5 {
    transition-delay: 4.5s;
}

.trn-d-5 {
    transition-delay: 5s;
}

.trn-d-6 {
    transition-delay: 6s;
}

.tranf-rotate-180 {
    transform: rotate(180deg);
}



/* transitons */
.tran-trnsf-easeout {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.flew-anim {
    left: -200px;
    top: 50px;
}


/* animations */

.move-up-anim {
    animation: marquee-move-up-anim 7s linear infinite;
}

@keyframes marquee-move-up-anim {
    0% {
        -webkit-transform: translateY(150%);
        transform: translateY(150%);
    }

    10% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-150%);
        transform: translateY(-150%);
    }
}


.move-top-anim {
    transform: translate(0px, 150px);
}


.move-right-anim {
    transform: translate(110px, 0px);
}

.move-left-anim {
    transform: translate(-110px, 0px);
}

.fade-anim {
    transition: all 0.4s ease-out;
    opacity: 0;
}

.spin-anim {
    animation-name: spinAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spin-anim-5s {
    animation-name: spin;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* spin animation single time */
.spin-anim-st {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: 0.5;
    animation-timing-function: linear;
}


/* .fd-up-anim{
    transition: all 0.4s ease-out;  
} */
.fd-up-anim .row-view:first-child {
    /* transition: all 0.4s ease-out;   */
    transition: all .5s ease-in-out;

    animation-name: fadeIn;
    animation-duration: .5s;
}

.dragonTigerAnimRL {
    animation-name: dragonTigerAnimRL;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-animation: linear infinite;
    -webkit-animation-name: dragonTigerAnimRL;
    -webkit-animation-duration: 3s;
}

.dragonTigerAnimLR {
    animation-name: dragonTigerAnimLR;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-animation: linear infinite;
    -webkit-animation-name: dragonTigerAnimLR;
    -webkit-animation-duration: 3s;
}

@keyframes dragonTigerAnimLR {
    0% {
        margin-left: 0px;
    }

    50% {
        margin-left: 25px;
    }

    100% {
        margin-left: 0px;
    }
}

@keyframes dragonTigerAnimRL {
    0% {
        margin-right: 0px;
    }

    50% {
        margin-right: 25px;
    }

    100% {
        margin-right: 0px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        height: 0;
    }

    to {
        opacity: 1;
        height: 30px;
    }
}

@keyframes spinAnim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinAnim {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinAnim {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}


@keyframes moveObjectLft {
    0% {
        background: red;
        left: 0px;
        top: 0px;
    }

    100% {
        background: red;
        left: -100px;
        top: 0px;
    }
}

@keyframes moveObjectRgt {
    0% {
        background: red;
        left: 0px;
        top: 0px;
    }

    100% {
        background: red;
        left: 50px;
        top: 0px;
    }
}

@keyframes driftRtLAnim {
    from {
        transform: translateX(500px);
    }

    to {
        transform: translateX(-750px);
    }
}

@keyframes driftTtBAnim {
    from {
        transform: translateY(-750px);
    }

    to {
        transform: translateY(500px);
    }
}

@media (max-width: 450px) {
    .res-h-100 {
        height: 80px !important;
    }

    /* responsive width */
    .res-wth {
        width: 100% !important;
    }

    .s-bet-btn {
        height: 45px !important;
    }

    .res-h-60-p {
        height: 58px !important;
    }
}

@media (max-width: 380px) {
    .res-h-100 {
        height: 65px !important;
    }

    .res-h-60-p {
        height: 50px !important;
    }
}

@media (max-width: 350px) {
    .res-h-60-p {
        height: 45px !important;
    }
}



.zoomAnimView {
    animation-name: animateZoom;
    animation-duration: 0.2s;
    animation-timing-function: linear;
}

.zoomAnimView2 {
    animation-name: animateZoom2;
    animation-duration: 0.7s;
    animation-direction: alternate-reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes animateZoom {
    0% {
        transform: scale(0.98);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes animateZoom2 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}


.rightToLeftAnimView {
    animation-name: animateRightToLeft;
    animation-duration: 0.1s;
    animation-timing-function: linear;
}

@keyframes animateRightToLeft {
    0% {
        transform: translateX(200px);
    }

    100% {
        transform: translateX(0px);
    }
}



/* Slideshow */
.slideshow {
    margin: 0 auto;
    overflow: hidden;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    background-color: var(--tar-black-color);
    /* margin: 15px; */
}

.slide {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}



.leaderboard-view {
    margin: 0 auto;
}

.leaderboard-view .container {
    width: 20000px;
    /* 300 x 4 */
    position: relative;
    -webkit-animation: dragLeftRight 50s ease-in-out infinite;
    -moz-animation: dragLeftRight 50s ease-in-out infinite;
    -ms-animation: dragLeftRight 50s ease-in-out infinite;
    -o-animation: dragLeftRight 50s ease-in-out infinite;
    animation: dragLeftRight 50s ease-in-out infinite;
}

.winner-slideshow .container div {
    width: 350px;
    height: 40px;
    float: left;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.winner-slideshow .container div span {
    color: #2ECC71 !important;
}

@-webkit-keyframes dragLeftRight {
    0% {
        left: 0;
    }

    5% {
        left: -300px;
    }

    10% {
        left: -600px;
    }

    15% {
        left: -900px;
    }

    20% {
        left: -1200px;
    }

    25% {
        left: -1500px;
    }

    30% {
        left: -1800px;
    }

    35% {
        left: -2100px;
    }

    40% {
        left: -2400px;
    }

    45% {
        left: -2700px;
    }

    50% {
        left: -3000px;
    }

    55% {
        left: -3300px;
    }

    60% {
        left: -3600px;
    }

    65% {
        left: -3900px;
    }

    70% {
        left: -4200px;
    }

    75% {
        left: -4500px;
    }

    80% {
        left: -4800px;
    }

    85% {
        left: -5100px;
    }

    90% {
        left: -5400px;
    }

    95% {
        left: -5700px;
    }

    100% {
        left: 0;
    }
}

@-moz-keyframes dragLeftRight {
    0% {
        left: 0;
    }

    5% {
        left: -300px;
    }

    10% {
        left: -600px;
    }

    15% {
        left: -900px;
    }

    20% {
        left: -1200px;
    }

    25% {
        left: -1500px;
    }

    30% {
        left: -1800px;
    }

    35% {
        left: -2100px;
    }

    40% {
        left: -2400px;
    }

    45% {
        left: -2700px;
    }

    50% {
        left: -3000px;
    }

    55% {
        left: -3300px;
    }

    60% {
        left: -3600px;
    }

    65% {
        left: -3900px;
    }

    70% {
        left: -4200px;
    }

    75% {
        left: -4500px;
    }

    80% {
        left: -4800px;
    }

    85% {
        left: -5100px;
    }

    90% {
        left: -5400px;
    }

    95% {
        left: -5700px;
    }

    100% {
        left: 0;
    }
}

@-ms-keyframes dragLeftRight {
    0% {
        left: 0;
    }

    5% {
        left: -300px;
    }

    10% {
        left: -600px;
    }

    15% {
        left: -900px;
    }

    20% {
        left: -1200px;
    }

    25% {
        left: -1500px;
    }

    30% {
        left: -1800px;
    }

    35% {
        left: -2100px;
    }

    40% {
        left: -2400px;
    }

    45% {
        left: -2700px;
    }

    50% {
        left: -3000px;
    }

    55% {
        left: -3300px;
    }

    60% {
        left: -3600px;
    }

    65% {
        left: -3900px;
    }

    70% {
        left: -4200px;
    }

    75% {
        left: -4500px;
    }

    80% {
        left: -4800px;
    }

    85% {
        left: -5100px;
    }

    90% {
        left: -5400px;
    }

    95% {
        left: -5700px;
    }

    100% {
        left: 0;
    }
}

@-o-keyframes dragLeftRight {
    0% {
        left: 0;
    }

    5% {
        left: -300px;
    }

    10% {
        left: -600px;
    }

    15% {
        left: -900px;
    }

    20% {
        left: -1200px;
    }

    25% {
        left: -1500px;
    }

    30% {
        left: -1800px;
    }

    35% {
        left: -2100px;
    }

    40% {
        left: -2400px;
    }

    45% {
        left: -2700px;
    }

    50% {
        left: -3000px;
    }

    55% {
        left: -3300px;
    }

    60% {
        left: -3600px;
    }

    65% {
        left: -3900px;
    }

    70% {
        left: -4200px;
    }

    75% {
        left: -4500px;
    }

    80% {
        left: -4800px;
    }

    85% {
        left: -5100px;
    }

    90% {
        left: -5400px;
    }

    95% {
        left: -5700px;
    }

    100% {
        left: 0;
    }
}

@keyframes dragLeftRight {
    0% {
        left: 0;
    }

    5% {
        left: -300px;
    }

    10% {
        left: -600px;
    }

    15% {
        left: -900px;
    }

    20% {
        left: -1200px;
    }

    25% {
        left: -1500px;
    }

    30% {
        left: -1800px;
    }

    35% {
        left: -2100px;
    }

    40% {
        left: -2400px;
    }

    45% {
        left: -2700px;
    }

    50% {
        left: -3000px;
    }

    55% {
        left: -3300px;
    }

    60% {
        left: -3600px;
    }

    65% {
        left: -3900px;
    }

    70% {
        left: -4200px;
    }

    75% {
        left: -4500px;
    }

    80% {
        left: -4800px;
    }

    85% {
        left: -5100px;
    }

    90% {
        left: -5400px;
    }

    95% {
        left: -5700px;
    }

    100% {
        left: 0;
    }
}

.white-sp-nw {
    white-space: nowrap !important;
}