:root {
  --primary-color: #fa3c09;
  --color-white: #ffffff;

  --red-color: #fa3c09;
  --green-color: #00c282;
  --dark-green-color: #27ae60;
  --blue-color: #0093ff;
  --sky-blue-color: #aacdfa;
  --cyan-blue-color: #1aa4af;
  --violet-color: #6655d3;
  --yellow-color: #ffc107;
  --orange-color: #ffa33b;
  --black-color: #000000;
  --puff-color: #ffdec3;
  --color-violet: #eb43dd;

  --grey-color: #666;
  --light-grey-color: #959ea6;

  --simple-black: rgba(0, 0, 0, 0.5);
  --light-black: rgba(0, 0, 0, 0.05);
  --light-black-2: rgba(0, 0, 0, 0.9);
  --light-black-3: rgba(0, 0, 0, 0.8);

  --simple-white: rgba(255, 255, 255, 0.5);
  --light-white: rgba(255, 255, 255, 0.6);
  --min-response-width: 430px;

  --bg-app-gradient-1: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
}

.appBackground {
  background: #9195a3;
}

.appContentsBackground {
  background: #22275b;
  /* background: #f7f8ff; */
  /* background: #9195a3 */
}

.respWidth {
  width: var(--min-response-width) !important;
}

.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

.overflowHidden {
  overflow: hidden !important;
}

.overflowScrollX {
  overflow-x: scroll !important;
}

.overflowScrollY {
  overflow-y: scroll !important;
}

.posRelative {
  position: relative !important;
}

.posAbsolute {
  position: absolute !important;
}

.posSticky {
  position: sticky !important;
}

.posFixed {
  position: fixed !important;
}

.colView {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

/* view align row wise */
.rowView {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

.viewCenter {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.hideView {
  display: none !important;
}

.spaceBetween {
  justify-content: space-between;
}

.flexDirColumn {
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.alignFlexStart {
  align-items: flex-start !important;
}

/* css: padding */
.pd5px {
  padding: 5px;
}

.pd10px {
  padding: 10px;
}

.pd15px {
  padding: 15px;
}

.pd30px {
  padding: 30px;
}

.pd0px5px {
  padding: 0px 5px;
}

.pd0px15px {
  padding: 0px 15px;
}

.pd5px10px {
  padding: 5px 10px;
}

.pd5px15px {
  padding: 5px 15px;
}

.pd10px15px {
  padding: 10px 15px;
}

.pd15px10px {
  padding: 15px 10px;
}

.pd15px20px {
  padding: 15px 20px;
}

.pd5px20px {
  padding: 5px 20px;
}

.pd5px25px {
  padding: 5px 25px;
}

.pd10px40px {
  padding: 10px 40px;
}

.pd10px80px {
  padding: 10px 80px;
}

.pd15px15px50px15px {
  padding: 15px 15px 50px 15px;
}

.pd15px45px50px45px {
  padding: 15px 45px 50px 45px;
}

.pd20px40px50px40px {
  padding: 20px 40px 50px 40px;
}

/* css: margin */
.mgT10px {
  margin-top: 10px;
}

.mgT15px {
  margin-top: 15px;
}

.mgT20px {
  margin-top: 20px;
}

.mgT25px {
  margin-top: 25px;
}

.mgT30px {
  margin-top: 30px;
}

.mgT50px {
  margin-top: 50px;
}

.mgL5px {
  margin-left: 5px;
}

.mgL10px {
  margin-left: 10px;
}

.mgB10px {
  margin-bottom: 10px;
}

.mgB100px {
  margin-bottom: 100px;
}

.fontS12Px {
  font-size: 12px;
}

.fontS13Px {
  font-size: 13px;
}

.fontS14Px {
  font-size: 14px;
}

.fontS16Px {
  font-size: 16px;
}

.fontS17Px {
  font-size: 17px;
}

.fontS18Px {
  font-size: 18px;
}

.fontS20Px {
  font-size: 20px;
}

.fontS22Px {
  font-size: 22px;
}

.fontS25Px {
  font-size: 25px;
}

.fontW400 {
  font-weight: 400;
}

.fontW500 {
  font-weight: 500;
}

.fontW600 {
  font-weight: 600;
}

.colorWhite {
  color: var(--color-white);
}

.colorLightWhite {
  color: var(--light-white);
}

.colorCustom1 {
  color: #61a9ff;
}

/* css: border radius */
.borderRadius {
  border-radius: 5px;
}

.borderRadius10px {
  border-radius: 10px;
}

.borderRadius20px {
  border-radius: 20px;
}

.borderRadius30px {
  border-radius: 30px;
}

/* css: border */
.border1pxSolidLightWhite {
  border: 1px solid rgba(255, 255, 255, 0.5);
}


/* css: height */
.height100vh {
  height: 100vh !important;
}

.height30px {
  height: 30px;
}

.height35px {
  height: 35px;
}

.height45px {
  height: 45px;
}

.height50px {
  height: 50px;
}

/* css: width */
.minWidth90px {
  min-width: 90px !important;
}

.widthFitContent {
  width: fit-content;
}

.width90 {
  width: 90% !important;
}
.width100 {
  width: 100% !important;
}

.width2px {
  width: 2px !important;
}

.width20px {
  width: 20px !important;
}

.width50px {
  width: 50px !important;
}

.width80px {
  width: 80px !important;
}

.width100px {
  width: 100px !important;
}

.width125px {
  width: 125px !important;
}

/* height & width */
.heightWidth15px {
  height: 15px;
  width: 15px;
}

.heightWidth18px {
  height: 18px !important;
  width: 18px !important;
}

.heightWidth25px {
  height: 25px;
  width: 25px;
}

.heightWidth45px {
  height: 45px;
  width: 45px;
}

.textAlignCenter {
  text-align: center !important;
}

.textDecoNone {
  text-decoration: none !important;
}

/* css: letter spacing */
.letterSpace1p8px {
  letter-spacing: 1.8px;
}

/* css: transition */
.transition05 {
  transition: 0.5s;
}

/* css: transform */
.transformRotate90deg {
  transform: rotate(90deg);
}

/* css: custom inputbox */
.inputBox {
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  font-size: 17px;
  transition: 0.1s;
  padding: 5px 15px;
  color: #ffffff;
  background: transparent;
}

.inputBox::placeholder {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
}

.inputBox:focus {
  border: 1px solid #61a9ff;
}

.hideInputBoxFocus:focus {
  border: 1px solid transparent;
}

.inputSelectBox {
  display: inline-block;
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  font-size: 17px;
  transition: 0.1s;
  padding: 5px 15px;
  color: #ffffff;
}

.inputSelectBox:focus {
  border: 1px solid #61a9ff;
}


/* css custom dialogview or toastview */
.noticeViewContents {
  width: 82%;
  min-height: 420px;
  border-radius: 10px;
  transition: 0.2s;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.1px 2px 8px 4px rgba(0, 0, 0, 0.05);
  background: #22275b;
}

.noticeTitle {
  font-size: 18px;
  text-align: center;
  color: rgb(255, 255, 0);
}

.noticeNote {
  font-size: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

/* background colors */
.bgWhite {
  background: var(--color-white);
}

.bgLightBlack {
  background: var(--light-black-3);
}

/* customizable colors */
.bgCustomColor1 {
  background: -webkit-linear-gradient(left, #374992 0%, #4675d2 100%);
  background: linear-gradient(90deg, #374992 0%, #4675d2 100%);
}

.bgCustomColor2 {
  background: -webkit-linear-gradient(top, #2aaaf3 0%, #2979f2 100%);
  background: linear-gradient(180deg, #2aaaf3 0%, #2979f2 100%);
}

.bgCustomColor3 {
  color: #61a9ff;
  background: transparent;
  border: 0.01333rem solid #61a9ff;
}

.bgCustomColor4 {
  background: rgb(43, 50, 112);
}

.bgCustomColor5 {
  background: #2b3270;
}

.bgCustomColor6 {
  background: #323a7c;
}

.bgCustomColor7 {
  background: #374992;
}

.bgDisable {
  background: #757ba6 !important;
}

.textOverflowDots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.zIndex_500{
  z-index: 500;
}

.zIndex_100{
  z-index: -100;
}


/* animations */
.zoomAnimView {
  animation-name: animateZoom;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}

@keyframes animateZoom {
  0% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 450px) {

  /* responsive width */
  .respWidth {
    width: 100% !important;
  }
}