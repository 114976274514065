:root {
  --min-response-width: 430px;
}

.dialogView {
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: var(--min-response-width);
  background: rgba(0, 0, 0, 0.09);
}
.dialogContentsView {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}
.dialogContents {
  text-align: center;
  max-width: 70%;
  min-width: 88px;
  width: 200px;
  min-height: 88px;
  border-radius: 6px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.8);
}
.dialogContents img {
  height: 35px;
}
.dialogContents div {
  color: #ffffff;
  font-weight: 200;
  word-break: break-word !important;
}

.dialogViewActive {
  display: block !important;
}
.dialogViewActive .dialogContents {
  animation: scaleUpAnimFrames 0.2s ease-out;
  animation-iteration-count: 1;
}
.dialogViewHide {
  animation: opacityDownAnimFrames 0.2s ease-out;
  animation-iteration-count: 1;
}

.dialogViewClose {
  z-index: -10;
  display: none !important;
}

@keyframes scaleUpAnimFrames {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacityDownAnimFrames {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 450px) {
  /* responsive width */
  .dialogView {
    width: 100% !important;
  }
}
