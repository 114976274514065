:root {
  --game-resp-width: 430px;
  --game-color-green: #18a058;
  --game-background: rgba(0, 0, 0, 0.09);
}

.w100 {
  width: 100%;
}
.w10px {
  width: 10px !important;
}
.w15px {
  width: 15px !important;
}
.w30px {
  width: 30px !important;
}
.w40px {
  width: 40px !important;
}
.w50px {
  width: 50px !important;
}
.respWidth {
  width: var(--game-resp-width);
}
.wFitContents {
  width: fit-content;
}

.h100vh {
  height: 100vh !important;
}
.h15px {
  height: 15px;
}
.h20px {
  height: 20px;
}
.minH450px {
  min-height: 450px !important;
}

.hW25px {
  height: 25px !important;
  width: 25px !important;
}

.posRelative {
  position: relative;
}

.rowView {
  display: flex;
}

.colView {
  display: flex;
  flex-direction: column;
}

.viewCenter {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.alignItemsCenter {
  align-items: center;
}
.jcSpaceBet {
  justify-content: space-between;
}
.flexDCol {
  flex-direction: column;
}
.hideView {
  display: none !important;
}

.textAlgEnd {
  text-align: end;
}
.textDecoNone {
  text-decoration: none !important;
}
.letterSpacing1p1px {
  letter-spacing: 1.05px;
}

.fontS12Px {
  font-size: 12px;
}
.fontS13Px {
  font-size: 13px;
}
.fontS14Px {
  font-size: 14px;
}
.fontS15Px {
  font-size: 15px;
}
.fontS16Px {
  font-size: 16px;
}
.fontS18Px {
  font-size: 18px;
}
.fontS20Px {
  font-size: 20px;
}
.fontS25Px {
  font-size: 25px;
}
.fontS30Px {
  font-size: 30px;
}

.fontW400 {
  font-weight: 400;
}
.fontW500 {
  font-weight: 500;
}
.fontW700 {
  font-weight: 700;
}

.pd5px{
  padding: 5px;
}
.pd10px {
  padding: 10px;
}
.pd15px {
  padding: 15px;
}
.pd0px15px {
  padding: 0px 15px;
}
.pd10px15px {
  padding: 10px 15px;
}
.pd12px0px {
  padding: 12px 0;
}
.pd5px25px {
  padding: 5px 25px;
}

.pdB5px {
  padding-bottom: 5px;
}

.mg10px15px {
  margin: 10px 15px;
}
.mgL5px {
  margin-left: 5px;
}
.mgL10px {
  margin-left: 10px;
}
.mgL15px {
  margin-left: 15px;
}

.mgT10px {
  margin-top: 10px;
}
.mgT15px {
  margin-top: 15px;
}
.mgT25px {
  margin-top: 25px;
}
.mgT30px {
  margin-top: 30px;
}
.mgT50px {
  margin-top: 50px;
}
.mgT60px {
  margin-top: 60px;
}

.mgB15px {
  margin-bottom: 15px;
}

.gameBg {
  background: var(--game-background) !important;
}

.bgDarkGrey {
  background: rgba(69, 54, 44, 0.8);
}
.bgDarkYellow {
  background: rgb(255, 218, 126);
}
.bgOrange {
  background: var(--color-orange) !important;
}
.bgBlue {
  background: var(--color-blue) !important;
}
.bgWhite {
  background: var(--color-white) !important;
}
.bgRed {
  background: var(--color-red) !important;
}
.bgGreen {
  background: var(--color-green) !important;
}
.bgLightWhite {
  background: rgba(255, 255, 255, 0.1) !important;
}
.bgPrimary {
  background: var(--primary-color) !important;
}

.borderRed {
  border: 1px solid #fd565c;
}
.borderGreen {
  border: 1px solid #47ba7c;
}

.colorRed {
  color: #fd565c;
}
.colorGreen {
  color: var(--game-color-green);
}
.colorYellow {
  color: #f0cc73;
}
.colorWhite {
  color: #ffffff;
}
.colorLightWhite {
  color: rgba(255, 255, 255, 0.7);
}

.borderRadius10px {
  border-radius: 10px;
}
.borderRadius15px {
  border-radius: 15px;
}

.overflowHidden {
  overflow: hidden !important;
}
.overflowYScroll {
  overflow-y: scroll !important;
}

.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

/* Game TopBar */
.gameTopBar {
  position: fixed;
  top: 0;
  height: 50px;
  width: var(--game-resp-width);
  z-index: 120;
  background: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.gameTopBar img {
  height: 20px;
}
/* .gameTopBar a img {
  height: 22px;
  width: 22px;
} */

.gameTopBar span.gameTopBarTitle {
  font-size: 17px;
  font-weight: 500;
}

/* game view & contents */
.gameView {
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  background: url(../GoldMiner/icons/bg.jpg) 0% 0% / 100% no-repeat
    rgb(144, 60, 24);
}

.bgGameStage {
  position: relative;
  background-image: url(../GoldMiner/icons/12.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 130px;
}

.gameBoxContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin-top: 15px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
}

.boxHolder {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}
.box {
  position: relative;
  flex: 0 0 calc(12.5% - 5px); /* Fixed width for 5 items in a row */
  box-sizing: border-box;
  margin: 2px; /* Space between items */
  border-radius: 5px;
  background: #6ab147;
  box-shadow: rgb(80, 148, 56) -3.09091px -3.09091px 3.09091px 0px inset;
}
.box::before {
  content: "";
  display: block;
  padding-top: 100%; /* Maintain square shape by setting height equal to width */
}

.box .profitBox {
  height: 80%;
  width: 80%;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  border-radius: 50%;
  background: rgba(249, 227, 35, 0.8);
  /* coin collect animation */
  animation: coinCollectAnimFrames 0.5s linear forwards;
  animation-iteration-count: 1;
  animation-delay: 0.7s;
}
.box .profitBox img {
  display: none;
  height: 15px;
  margin-right: 5px;
}
.box .minesBox {
  display: none;
  height: 100%;
  width: 100%;
}
.box .minesBox img {
  width: 55%;
}
.lostBox {
  background: linear-gradient(rgb(229, 105, 111) 0%, rgb(198, 56, 60) 100%);
  box-shadow: rgb(196, 17, 33) -3.05455px -3.05455px 3.05455px 0px inset;
}
.lostBox .minesBox {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.wonBox {
  background: rgb(69, 119, 64) !important;
  box-shadow: rgb(54, 101, 49) -3.05455px -3.05455px 3.05455px 0px inset !important;
}
.wonBox .profitBox {
  display: flex !important;
}

.boxCount2 .box {
  margin: 5px;
  flex: 0 0 calc(50% - 10px) !important;
}
.boxCount2 .box .profitBox {
  height: 35%;
  width: 35%;
  font-size: 16px;
}
.boxCount2 .box .minesBox img {
  width: 40%;
}

.boxCount4 .box {
  margin: 5px;
  flex-basis: 25%;
  flex-basis: calc(25% - 10px);
  /* flex: 0 0 calc(25% - 10px) !important; */
}
.boxCount4 .box .profitBox {
  height: 55%;
  width: 55%;
  font-size: 14px;
}

/* .box .profitBox img{

} */

/* Optional: Hide scrollbar */
.boxHolder::-webkit-scrollbar {
  display: none;
}
.boxHolder {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.startPlayBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110px;
  height: 110px;
  z-index: 1;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background: rgba(192, 110, 26, 0.8);
}

.startPlayBtn img {
  width: 100%;
}

.gameOptionBtn {
  height: 30px;
  width: 65px;
  padding: 5px 10px;
  color: #000000;
  background-image: url(../GoldMiner/icons/2.png);
  background-size: cover;
  cursor: default;
}
.gameOptionBtn:not(:first-child) {
  margin-left: 15px;
}

.bgActiveGameOption {
  color: rgb(248, 222, 34);
  background-image: url(../GoldMiner/icons/1.png) !important;
}

.tabView {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.tabView div {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  border-radius: 21px;
  color: #fd8;
  background: linear-gradient(180deg, #a57543 0%, #7b5732 100%);
  box-shadow: 0 2px 3px #472f1e;
  text-align: center;
  white-space: nowrap;
  cursor: default;
}
.tabView div.tabViewActive {
  background: #45362c;
  box-shadow: 0 1px #836545, inset 0 -1px 3px #0000001a;
}
.tabView div:not(:first-child) {
  margin-left: 10px;
}

.GameOptionsChooseView {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 10px;
  background: #ffffff;
}
.GameOptionsChooseView .GameOptionsChooseViewItem {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
}
.GameOptionsChooseView .GameOptionsSelectedView {
  color: #ffffff;
  background: -webkit-linear-gradient(297deg, #374992 5.33%, #4675d2 93.42%);
  background: linear-gradient(153deg, #374992 5.33%, #4675d2 93.42%);
  box-shadow: 0 -0.05333rem 0.16rem #92a7ff inset;
  border-radius: 10px;
}

.res-app-logo {
  height: 45px !important;
}
.res-wallet-btns a {
  font-size: 18px !important;
  padding: 5px 35px !important;
  text-decoration: none;
}
.res-wallet-btns a:last-child {
  margin-left: 15px;
}

/* css: tabview */
.tabOptionsView {
  width: 100%;
  display: flex;
  align-items: center;
}
.tabOptionsView .tabOptionViewItem {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  height: 48px;
  white-space: nowrap;
  line-height: 48px;
  transition: 0.2s;
  cursor: pointer;
  overflow: hidden;
  font-size: 18px;
  margin-top: -10px;
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
}
.tabOptionsView .tabSelectedView {
  color: #ffffff;
  background: -webkit-linear-gradient(top, #2aaaf3 0%, #2979f2 100%);
  background: linear-gradient(180deg, #2aaaf3 0%, #2979f2 100%);
}

.everyOneOrdersView > div:not(:last-child) {
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.05);
}
.myGameRecordsView {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.myGameRecordsViewItem {
  display: flex;
  flex-direction: column;
}
.myGameRecordsView .myGameRecordsViewItem:not(:first-child) {
  margin-top: 15px;
}
.myGameRecordsViewItem .myGameRecordsContents {
  padding: 10px 0;
}
.myGameRecordsViewItem:not(:last-child) .myGameRecordsContents {
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.08);
}
.myGameRecordsViewItem .resultShortView {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  background: #6ab147;
  box-shadow: rgb(80, 148, 56) -3.09091px -3.09091px 3.09091px 0px inset;
}
.myGameRecordsViewItem .resultShortView img {
  height: 40px;
}
.myGameRecordsViewItem .mygameShortDetailsView {
  flex: 1;
  margin-left: 15px;
}
.myGameRecordsViewItem .mygameShortDetailsView span {
  display: block;
}
.myGameRecordsViewItem .mygameShortDetailsView .gameShortDetailsResult {
  font-size: 13px;
  padding: 2px 15px;
  border-radius: 5px;
}

.myGameRecordsViewItem .gameShortDetailsResultGreen {
  color: #47ba7c;
  border: 1px solid #47ba7c;
}
.myGameRecordsViewItem .gameShortDetailsResultRed {
  color: #fd565c;
  border: 1px solid #fd565c;
}

.mygameRecordsDetails {
  margin-top: 15px;
}
.mygameRecordsDetailsTitle {
  font-size: 20px;
}

.stopExitBtn {
  height: 50px;
  width: 140px !important;
}

.profitAmountView {
}

/* betting dialog */
.bettingDialog {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5) !important;
}

.bettingDialogContents {
  position: fixed;
  bottom: 0;
  padding: 36px 24px;
  background: #ffffff;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.bettingDialogActive .bettingDialogContents {
  animation: slideUpAnimFrames 0.3s ease-out;
  animation-iteration-count: 1;
}

.bettingDialogClose .bettingDialogContents {
  animation: slideDownAnimFrames 0.3s ease-out;
  animation-iteration-count: 1;
}

.bettingDialogHide {
  display: none;
  z-index: -100 !important;
}

.bettingDialogContents .pointView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 5px;
  background: #f7f6fb;
  border-radius: 8px;
  border: 1px solid #eceaf2;
}
.bettingDialogContents .pointView span {
  font-size: 20px;
  font-weight: 500;
}
.bettingDialogContents .pointView .btn {
  width: 96px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  color: #fff;
  background: #fc940e;
  box-shadow: 0 4px #e68104;
  border-radius: 8px;
  text-decoration: none;
}

.bettingDialogContents .chooseAmountsView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.bettingDialogContents .chooseAmountsView .btn {
  width: 64px;
  height: 64px;
  line-height: 64px;
  background: linear-gradient(180deg, #faecda 0%, #efddc8 100%);
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  color: #66400a;
  cursor: default;
}

.bettingDialogContents .updateQuantityView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
  background: #f7f6fb;
  border-radius: 8px;
  border: 1px solid #eceaf2;
}
.bettingDialogContents .updateQuantityView div {
  height: 35px;
  line-height: 35px;
  font-size: 23px;
  padding: 0 15px;
}
.bettingDialogContents .updateQuantityView input {
  width: 50%;
  border: none;
  outline: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
}

.bettingDialogContents .controlOptions {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bettingDialogContents .controlOptions .btn {
  height: 52px;
  line-height: 52px;
  background: #1aa4af;
  box-shadow: 0 4px #058c94;
  border-radius: 8px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  cursor: default;
}
.bettingDialogContents .controlOptions .btn:first-child {
  width: 30%;
  background: #45362c;
  box-shadow: 0 4px #45362c;
}
.bettingDialogContents .controlOptions .btn:last-child {
  width: 70%;
  margin-left: 5px;
}

/* required components view */
.circleLoadView {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.05);

  -webkit-animation: spinAnimFrames 0.5s linear infinite; /* Safari */
  animation: spinAnimFrames 0.5s linear infinite;
}
.hideCircleLoadView {
  display: none !important;
}

/* Safari */
@-webkit-keyframes spinAnimFrames {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinAnimFrames {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* animations */
.scaleAnimation {
  animation: scaleAnimFrames 1s infinite 0s linear;
}
@keyframes scaleAnimFrames {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.88);
  }
  100% {
    transform: scale(1);
  }
}

.fadeInAnimation > div:first-child {
  transition: all 1s ease-in-out;
  animation-name: fadeInAnimFrames;
  animation-duration: 1s;
}

@keyframes fadeInAnimFrames {
  0% {
    opacity: 0;
    background: rgba(255, 255, 255, 0.08);
  }

  90% {
    opacity: 1;
  }

  100% {
    background: none;
  }
}

.ringBellAnimation {
  animation: ringBellAnimFrames 0.5s infinite 0s linear;
}

@keyframes ringBellAnimFrames {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.coinCollectAnimation {
  animation: coinCollectAnimFrames 0.5s ease;
  animation-iteration-count: 0;
  animation-delay: 1.5s;
}

@keyframes coinCollectAnimFrames {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
    transform: scale(0.7);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
    transform: translateY(-50px);
  }
}

@keyframes slideUpAnimFrames {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownAnimFrames {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@media (max-width: 430px) {
  .respWidth {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .startPlayBtn {
    width: 100px;
    height: 100px;
  }

  .gameOptionBtn:not(:first-child) {
    margin-left: 10px;
  }
}

/* no transition effect */
.notransition {
  animation: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
